import React from 'react';

const Description = () => {
	return <>
		<h1><img alt="logo" src="logo.png" />Othellish</h1>
		Same rules as Othello, but both make their draws at the same time.
		Both players will flip the expected tiles.
		<br />
		In case you select the same place for a tile
		then no one gets the place during next round.
	</>
}

export default Description;
