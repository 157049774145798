import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Login';
import BoardSelect from './BoardSelect';
import Lobby from './Lobby';
import Game from './Game';
import ChangeRoute from './ChangeRoute';
import Description from './Description';
import Footer from './Footer';
import Admin from './Admin';
import Banner from './Banner';

function Main({ socket }) {
	var [game, setGame] = useState({});
	var [lobbyList, setLobbyList] = useState(null);
	var [goto, setGoto] = useState("/");

	const leave = () => {
		socket.volatile.emit("leave", {
			userId: window.localStorage.getItem("userId"),
		});
	};

	socket.on("goto", (r, callback) => {
		console.log("goto", r);
		setGoto(r['goto']);
	});

	socket.on("error", (r, callback) => {
		console.log("error", r);
		console.log("error", r.message);
		setGoto(r['goto']);
	});

	socket.on("whoareyou", (data, callback) => {
		var userId = window.localStorage.getItem('userId');
		if (userId) {
			socket.volatile.emit("me", {
				userId: userId
			});
		} else {
			setGoto("/");
		}
	});

	socket.on("userinfo", (r, callback) => {
		console.log("userinfo", r);
		window.localStorage.setItem('userId', r["userId"]);
		window.localStorage.setItem('userName', r["userName"]);
		setGoto(r['goto']);
	});

	socket.on("lobby", (r) => {
		console.log("lobby", r);
		if (r !== lobbyList)
			setLobbyList(r);
	});


	socket.on("newboard", (r, callback) => {
		console.log("newgame", r);
		window.localStorage.setItem('boardId', r["boardId"]);
		window.localStorage.setItem('userId', r["userId"]);
		window.localStorage.setItem('gameName', r["gameName"]);
		setGoto(r['goto']);
	});

	socket.on("gamelayout", (r) => {
		console.log("gamelayout");
		window.localStorage.setItem('boardId', r["boardId"]);
		setGame(r["game"]);
		setGoto("/game");
	});

	return (
		<>
			<Description />
			<BrowserRouter>
				<ChangeRoute goto={goto} />
				<Routes>
					<Route path="/" element={<Login socket={socket} />}></Route>
					<Route path="/BoardSelect" element={<BoardSelect socket={socket} />}></Route>
					<Route path="/lobby" element={<Lobby socket={socket} lobbyList={lobbyList} />}></Route>
					<Route path="/game" element={<Game socket={socket} game={game} />}></Route>
					<Route path="/admin" element={<Admin socket={socket} />}></Route>
				</Routes>
			</BrowserRouter>
			<hr />
			<button onClick={leave}>Leave</button>
			<Footer />
			<Banner />
		</>
	);
}

export default Main;
