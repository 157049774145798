import React, { useState } from 'react';
const get = (value, def) => { return value != null ? value : def; };

const Login = ({ socket }) => {
	const [userName, setUserName] = useState(get(window.localStorage.getItem('userName'), ""));
	const handleSubmit = (e) => {
		console.log("handleSubmit", "Login", userName);
		e.preventDefault();
		socket.volatile.emit("login", {
			userId: get(window.localStorage.getItem("userId"), ""),
			userName: userName,
		});
	};
	let active = userName !== "";

	console.log("username is", userName);

	return (
		<form className="home__container" onSubmit={handleSubmit}>
			<h2 className="home__header">Sign in before joining game</h2>
			<label htmlFor="name">User name</label>
			<input
				type="text"
				placeholder="name"
				minLength={1}
				name="name"
				id="name"
				className="usernNme__input"
				value={userName}
				onChange={(e) => setUserName(e.target.value)}
			/>
			<button disabled={!active} className="home__cta">Register</button>
		</form >
	);
};
export default Login;