import React, { useState } from 'react';
import socketIO from 'socket.io-client';


const Admin = ({ socket }) => {
	const [data, setData] = useState({});
	const [password, setPassword] = useState("");
	var handleSubmit = (e) => {
		e.preventDefault();
		socket.emit("admin", {
			password: password,
		},
			(resp) => {
				console.log("callback", resp);
				setData(resp)
			});
	};
	console.log("data", data);
	return <>
		<h1>Admin</h1>
		<form className="home__container" onSubmit={handleSubmit}>
			<input
				type="pwd"
				placeholder="password"
				minLength={4}
				name="pwd"
				id="pwd"
				className="usernNme__input"
				onChange={(e) => setPassword(e.target.value)}
			/>
			<button className="home__cta">view</button>
		</form>
		Boards: {data.boards}<br />
		Lobby: {data.lobby}<br />
	</>
}

export default Admin;