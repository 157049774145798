import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Admin from './Admin';
import Main from './Main';
import socketIO from 'socket.io-client';

const socket = socketIO.connect(process.env.REACT_APP_PRODUCTION ? 'wss://othellish.haxx.se/' : 'ws://localhost:3000/');

function App() {
  if (window.location.pathname.indexOf("admin") > 0)
    return <Admin socket={socket} />
  return <Main socket={socket} />
}

export default App;
