/*
Visar reklam.
*/
import React from "react";
import { Adsense } from '@ctrl/react-adsense';

class Banner extends React.Component {
    // <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    render() {
        const publishid = 'ca-pub-6613678989595773';
        const slotid = "9731885405";
        let add;
        switch (3) {
            case 0:
                // ads with no set-up
                add = <Adsense
                    client={publishid}
                    slot={slotid}
                />;
                break;

            case 1:
                // ads with custom format
                add = <Adsense
                    client={publishid}
                    slot={slotid}
                    style={{ width: 500, height: 300, float: 'left' }}
                    format=''
                />;
                break;

            case 2:
                // responsive and native ads
                add = <Adsense
                    client={publishid}
                    slot={slotid}
                    style={{ display: 'block' }}
                    layout='in-article'
                    format='fluid'
                />;
                break;

            case 3:
                // auto full width responsive ads
                add = <Adsense
                    client={publishid}
                    slot={slotid}
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                />;
                break;
            default:
                break;
        }
        return <>
            {add}
        </>;
    }
}

export default Banner;
