import React from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

const ChangeRoute = ({ goto }) => {
	const location = useLocation();
	if (goto != null && goto !== location.pathname) {
		console.log("goto", goto);
		return <Navigate to={goto} />
	}
	return <></>
}

export default ChangeRoute;