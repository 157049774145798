import React, { useState } from 'react';
const get = (value, def) => { return value ? value : def; };

const BoardSelect = ({ socket }) => {
	const [boardName, setboardName] = useState(get(window.localStorage.getItem('boardName'), ""));
	const handleSubmit = (e) => {
		e.preventDefault();
		window.localStorage.setItem('boardName', boardName);
		socket.volatile.emit("newboard", {
			boardName: boardName,
			userId: window.localStorage.getItem("userId")
		});
	};
	let active = boardName !== "";

	return (
		<form className="home__container" onSubmit={handleSubmit}>
			<h2 className="home__header">Select board</h2>
			<label htmlFor="boardName">Game name</label>
			<input
				type="text"
				minLength={1}
				name="boardName"
				id="boardName"
				className="username__input"
				value={boardName}
				onChange={(e) => setboardName(e.target.value)}
			/>
			<br />
			<button disabled={!active} className="home__cta">Join game</button>
			<button disabled={!active} className="home__cta">Create game</button>
		</form >
	);
};
export default BoardSelect;