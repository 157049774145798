import React from 'react';

const Footer = () => {
	return <>
		<hr />
		<span style={{ fontSize: "90%" }}>
			Copyright <a href="https://kjell.haxx.se/">Kjell Ericson</a>.
			Computer player by <a href="https://haxx.se/home/games/othello/">Per Persson</a>.
		</span>
	</>
}

export default Footer;
