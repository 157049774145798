import React, { useState } from 'react';
import styled from 'styled-components';

const PartnerButton = styled.button`
	padding: 4px;
	margin: 5px;
	border: 1px solid black;
	border-radius: 3px;
`;

const YourselfButton = styled(PartnerButton)`
`;
const UnselectedButton = styled(PartnerButton)`
	background-color: white;
`;
const SelectedButton = styled(PartnerButton)`
	background-color: lightgreen;
`;
const InvitedButton = styled(PartnerButton)`
	background-color: yellow;
`;

const Partner = (props) => {
	const { socket, partner, lobbyList } = { ...props };

	const invitePartner = (socket, lobbyId) => () => {
		console.log("invitePartner", lobbyId);
		socket.volatile.emit("invitePartner", {
			userId: window.localStorage.getItem("userId"),
			lobbyId: lobbyId,
		});
	}

	if (partner.lobbyId === lobbyList.lobbyId) {
		return <YourselfButton>
			{partner.userName}
		</YourselfButton>
	}

	for (let r of lobbyList.partnersSelected) {
		if (partner.lobbyId === r.lobbyId) {
			return <SelectedButton><b>{r.userName}</b> is challenged</SelectedButton>
		}
	}
	for (let r of lobbyList.partnersInvites) {
		if (partner.lobbyId === r.lobbyId) {
			return <InvitedButton onClick={invitePartner(socket, partner.lobbyId)}>
				<b>{r.userName}</b> has challanged you
			</InvitedButton>
		}
	}
	return <UnselectedButton onClick={invitePartner(socket, partner.lobbyId)} >
		{partner.userName}
	</UnselectedButton>
}

const Computer = (props) => {
	const { socket } = { ...props };

	const invitePartner = (socket) => () => {
		console.log("inviteComputer");
		socket.volatile.emit("invitePartner", {
			userId: window.localStorage.getItem("userId"),
			lobbyId: "computer",
		});
	}

	return <UnselectedButton onClick={invitePartner(socket)} >
		Computer
	</UnselectedButton>
}

const Lobby = (props) => {
	const { socket, lobbyList } = { ...props };
	console.log("Show Lobby", lobbyList);
	var [computerOnline, setComputerOnline] = useState(false);

	socket.volatile.emit("status", {}, (response, r) => {
		console.log("response", r);
		setComputerOnline(r === "ok");
	});

	if (!lobbyList)
		return null;
	return (
		<>
			<h2>Lobby</h2>
			Challenge your preferred oppenent below.
			<br />
			{computerOnline ?
				<>
					<Computer socket={socket} />
					<br />
					<br />
				</>
				:
				<></>
			}

			{lobbyList.list.length === 0 ?
				<>Waiting for some humans to show up...</>
				:
				<>
					When the other user (or you) has challanged each other the game will start.
					<br />
					{lobbyList.list.map((row, index) => {
						return <Partner key={index} socket={socket} partner={row} lobbyList={lobbyList} />;
					})}
				</>
			}
		</>
	);
};
export default Lobby;