import React from 'react';
import styled from 'styled-components';
import CrossfadeImage from 'react-crossfade-image';
import './style.css';
import UIfx from 'uifx';
import flipMp3 from './flip.mp3';
import wrongMp3 from './wrong.mp3';

const flipSound = new UIfx(flipMp3);
const wrongSound = new UIfx(wrongMp3);

const makeDraw = (props) => {
	const { socket, x, y } = props;
	console.log("handledraw", x, y);
	socket.volatile.emit("draw", {
		userId: window.localStorage.getItem("userId"),
		x: x,
		y: y
	});
};

const MarkerContent = styled.span`
	padding: 2px;
`;


const Marker = (props) => {
	const { x, y, playSound, type, lastDraws } = props;
	const duration = 500;
	var delay = 1;
	var addDelay = 0;
	const imgstyle = { maxWidth: "32px", maxHeight: "32px", display: "inline" };
	if (lastDraws && lastDraws.length > 0) {
		var max1 = 8;
		if (lastDraws[0].x >= 0) {
			max1 = Math.max(Math.abs(lastDraws[0].x - x), Math.abs(lastDraws[0].y - y));
			addDelay = 500;
		}
		var max2 = 8;
		if (lastDraws.length > 1 && lastDraws[1].x >= 0) {
			max2 = Math.max(Math.abs(lastDraws[1].x - x), Math.abs(lastDraws[1].y - y));
		}
		delay += Math.min(max1, max2) * 100;
	}
	switch (type) {
		case 0:
			return <MarkerContent>
				<CrossfadeImage duration={duration} style={imgstyle} alt="green" src="green.gif" />
			</MarkerContent>;
		case 1:
			return <MarkerContent>
				<CrossfadeImage duration={duration} style={imgstyle} alt="black" src="black.gif" />
			</MarkerContent>;
		case 2:
			return <MarkerContent>
				<CrossfadeImage duration={duration} style={imgstyle} alt="white" src="white.gif" />
			</MarkerContent>;
		case 3:
			if (playSound) {
				setTimeout(() => {
					flipSound.play();
				}, delay);
			}
			return <MarkerContent>
				<CrossfadeImage delay={delay} duration={duration} style={imgstyle} alt="black" src="newblack.gif" />
			</MarkerContent>;
		case 4:
			if (playSound) {
				setTimeout(() => {
					flipSound.play();
				}, delay + addDelay);
			}
			return <MarkerContent>
				<CrossfadeImage delay={delay + addDelay} duration={duration} style={imgstyle} alt="white" src="newwhite.gif" />
			</MarkerContent>;
		case 5:
			return <MarkerContent onClick={() => makeDraw(props)}>
				<CrossfadeImage delay={1000} duration={duration} style={imgstyle} alt="legal move" src="legalmove.gif" />
			</MarkerContent>;
		case 6:
			return <MarkerContent onClick={() => makeDraw(props)}>
				<CrossfadeImage duration={duration} style={imgstyle} alt="possible move" src="question.gif" />
			</MarkerContent>;
		default:
			break;
	};

	if (playSound) {
		setTimeout(() => {
			wrongSound.play();
		}, 100);
	}

	return <MarkerContent>
		<CrossfadeImage delay={delay} duration={duration} style={imgstyle} alt="blocked" src="blocked.gif" />
	</MarkerContent>;
};

const Game = ({ socket, game }) => {
	console.log("game", game);
	if (game === undefined || !("users" in game)) {
		return <></>
	}
	return (
		<>
			<div style={{ padding: "2px", margin: "4px" }}>

				{game.points[1]} points&nbsp;
				<img alt="black" src="black.gif" />&nbsp;
				YOU
				<br />
				{game.points[2]} points&nbsp;
				<img alt="white" src="white.gif" />&nbsp;
				{game.users[1]}
			</div>

			<span style={{ padding: "2px", margin: "4px", backgroundColor: "lightblue", border: "1px solid black" }}>
				{game.status}
			</span>
			{
				game.layout.map((row, y) => {
					return <div key={y}>
						{row.map((col, x) => {
							return <Marker
								key={x}
								lastDraws={game.lastDraws}
								socket={socket}
								y={y}
								x={x}
								playSound={game.flipCoins}
								type={col} />
						})}
					</div>
				})
			}
		</>
	);
};
export default Game;